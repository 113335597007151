// SETTER
export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_ERROR_STATE = 'SET_ERROR_STATE';
export const SET_TAB_STATE = 'SET_TAB_STATE';
export const SET_SERIES_DATA = 'SET_SERIES_DATA';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_YSTV_SERIES_DATA = 'SET_YSTV_SERIES_DATA';
export const PUT_YSTV_SERIES_DATA = 'PUT_YSTV_SERIES_DATA';

// GETTER
export const GET_SERIES_DATA = 'GET_SERIES_DATA';
export const GET_YSTV_SERIES_DATA = 'GET_YSTV_SERIES_DATA';

// Featured Series
export const GET_YSTV_FEATURED_SERIES_DATA = 'GET_YSTV_FEATURED_SERIES_DATA';
export const SET_YSTV_FEATURED_SERIES_DATA = 'SET_YSTV_FEATURED_SERIES_DATA';
export const PUT_YSTV_FEATURED_SERIES_DATA = 'PUT_YSTV_FEATURED_SERIES_DATA';
